.project-name {
    font-size: 400%;
    font-weight: bold;
}

.tech-stack {
    color: rgb(148, 161, 178);
    font-weight: bold;
    font-size: 150%;
}

.project-links {
    color: rgb(127, 90, 240);
    font-weight: 800;
}

.projects-row {
    height: 100vh;
}

#viberant {
    max-height: 100%;
    max-width: 100%;
    margin: 0 auto;
    display: block;
}

#spellbook {
    max-height: 50vh;
    max-width: 100%;
    margin: 0 auto;
    display: block;
}

#chat-app {
    max-height: 50vh;
    max-width: 100%;
    margin: 0 auto;
    display: block;
}