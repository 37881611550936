.contact-name {
    font-size: 10vw;
    font-weight: bold;
}

.contact-title {
    font-size: 5vw;
    font-weight: bold;
    color: #7F5AF0;
}

.contact-left {
    font-weight: bold;
    margin: 5px 0;
}

.contact-body {
    margin: 5px 0;
}

#space-jorge {
    display: block;
    margin: 0 auto;
    height: 50vw;
    border-radius: 100%;
}


@media screen and (min-width: 992px) {
    #space-jorge {
        display: block;
        margin: 0 auto;
        height: 50vh;
        border-radius: 100%;
    }

    .contact-name {
        font-size: 3vw;
    }

    .contact-title {
        font-size: 2vw;
    }
}