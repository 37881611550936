#jumbo-div {
    display: none;
}

.slide {
    opacity: 1;
    animation: slide-in 2s ease-in;
}

.my-photo {
    opacity: 0;
    animation: fade-in 2s ease-in 2s forwards;
    max-height: 95%;
    max-width: 95%;
}

#down-arrow {
    height: 5vh;
    width: 5vh;
    color: rgb(148, 161, 178);
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 0;
    animation-name: bounce;
    animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-delay: 2s;
}

@keyframes grow-in {
    0% {width: 0;}
    80% {width: 22vw;}
    100% {width: 20vw;}
}

@keyframes slide-in {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    80% {
        opacity: 1;
        transform: translateX(5%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes fade-in {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

@keyframes bounce {
    0%   { transform: scale(1,1)      translateY(0); }
    10%  { transform: scale(1.1,.9)   translateY(0); }
    30%  { transform: scale(.9,1.1)   translateY(-100px); }
    50%  { transform: scale(1.05,.95) translateY(0); }
    57%  { transform: scale(1,1)      translateY(-7px); }
    64%  { transform: scale(1,1)      translateY(0); }
    100% { transform: scale(1,1)      translateY(0); }
}

@media screen and (min-width: 992px) {
    #jumbo-div {
        display: block;
        height: 100vh;
        width: 20vw;
        background-color: #7F5AF0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
        animation: grow-in 2s ease-in;
    }

    .my-photo {
        opacity: 0;
        animation: fade-in 2s ease-in 2s forwards;
        max-height: 60vh;
        max-width: 60vw;
    }
}